@import "../variables";

.header {
  background: #040707;

  &__wrapper {
    padding: 20px 0;
    display: flex;
    align-items: center;
    height: 80px;
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include bp(mobile) {
      left: unset;
      right: 0;
      height: 45px;
      width: 45px;
    }
  }

  &__icon {
    &--back {
      opacity: 0;
      margin-left: 20px;
      font-size: 21px;
      transition: all 0.3s;
    }

    &--home {
      opacity: 1;
      position: absolute;
      left: 0px;
      color: #bf9c3b;
      font-size: 20px;
      transition: all 0.2s;
    }
  }

  &__nav {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      height: 2px;
      background: rgba($color: #ffffff, $alpha: .1);
      opacity: 0;
    }

    &--item {
      display: flex;
      align-items: center;

      &:hover {
        .header {
          &__nav {
            &--text {
              color: #bf9c3b;
            }
          }
          &__icon {
            &--back {
              opacity: 1;
              margin-left: 0;
              color: #bf9c3b;
            }

            &--home {
              opacity: 0;
            }
          }
        }
      }
    }

    &--text {
      font-size: 19px;
      letter-spacing: 1.2px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      white-space: nowrap;
      left: 30px;
      transition: all 0.3s;

      @include bp(mobile) {
        font-size: 17px;
      }
    }

    &--icon {
      font-size: 21px;
      color: #bf9c3b;
      margin-left: 15px;
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &-home {
    display: none;

    @include bp(tablet) {
      display: block;
      position: fixed;
      width: 100vw;
      z-index: 22;
    }

    .header {
      &__wrapper {
        transition: all 0.3s;

        @include bp(tablet) {
          display: block;
          height: 90px;
        }

        @include bp(mobile) {
          height: 80px;
          padding: 16px 0;
        }

        &.active {
          height: auto;

          .header {
            &__nav {
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
      &__nav {
        @include bp(tablet) {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }
      }
      &__logo {
        position: static;
        left: unset;
        transform: unset;
      }
      &__content {
        position: relative;
        top: -50px;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;

        &.active {
          top: 0;
          opacity: 1;
          pointer-events: auto;
          margin-top: 15px;
        }
      }
    }
  }

  &__hamburger {
    position: relative;
    width: 45px;
    height: 36px;
    cursor: pointer;

    .line {
      position: absolute;
      width: 45px;
      height: 2px;
      right: 0;
      background: #ffffff;
      transition: all 0.3s;

      &:nth-child(2) {
        width: 35px;
        top: 50%;
      }
      &:nth-child(3) {
        width: 30px;
        bottom: 0;
      }
    }

    &:hover {
      .line {
        &:nth-child(1) {
          background: #bf9c3b;
        }
        &:nth-child(2) {
          width: 45px;
          background: #bf9c3b;
        }
        &:nth-child(3) {
          width: 45px;
          background: #bf9c3b;
        }
      }
    }

    &.active {
      .line {
        background: #bf9c3b;

        &:nth-child(1) {
          transform: rotate(45deg);
          top: calc(50% - 2px);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          width: 100%;
          bottom: calc(50% + 0px);
        }
      }
    }
  }
}
