@import "../variables";

.home {
  &__inner {
    position: absolute;
    top: 250px;
    transform: translateY(-50%);
    width: 880px;
    left: 140px;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
    transition: all .3s;

    &.active {
      z-index: 3;
      opacity: 1;
      pointer-events: auto;
    }


    @include bp(desktop-m) {
      top: 210px;
      width: calc(100% - 80px);
      left: 80px;
    }

    @include bp(desktop-ui) {
      top: 180px;
      width: calc(100% - 60px);
      left: 60px;
    }

    @include bp(tablet) {
      width: 670px;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
    }
    @include bp(mobile) {
      width: 90%;
      top: 30px;
    }
    @media screen and (max-width: 366px) {
      top: 15px;
    }
  }

  &__title {
    color: #ffffff;
    font-size: 6em;
    font-family: cursive;
    opacity: .7;
    line-height: 1.4;

    @include bp(desktop-m) {
      font-size: 4.4em;
      width: 630px;
    }

    @include bp(desktop-ui) {
      font-size: 4em;
    }
    @include bp(mobile) {
      width: 100%;
      font-size: 3.2em;
      margin-bottom: 5px;
    }

    @include bp(mobile-small) {
      font-size: 2.5em;
    }
  }

  &__desc {
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    opacity: 0.7;
    line-height: 1.3;
    letter-spacing: .8px;

    @include bp(desktop-ui) {
      width: calc(100% - 80px);
    }
    @include bp(mobile) {
      width: 100%;
      font-size: 17px;
    }
    @include bp(mobile-xs) {
      width: 100%;
      font-size: 16px;
    }
  }

  &__info {
    position: absolute;
    right: 0;
    bottom: 8vh;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 2;

    @include bp(tablet) {
      flex-direction: inherit;
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      justify-content: center;
      bottom: 16vh;
    }

    @include bp(mobile) {
      bottom: 18vh;
    }

    @media screen and (max-width: 360px) {
      bottom: 19vh;
    }

    &--item {
      background: rgba(0, 0, 0, 0.85);
      opacity: 0.7;
      color: #ffffff;
      font-weight: 500;
      width: 250px;
      border-radius: 5px 0 0 5px;
      text-align: left;
      padding: 14px 20px;
      overflow: hidden;
      margin-top: 10px;
      transition: all 0.3s;

      @include bp(tablet) {
        margin: 0 20px;
        border-radius: 5px;
        width: auto;
      }
      @include bp(mobile) {
        width: auto;
        margin: 0 10px;

        &:last-child {
          bottom: -60px;
        }
      }

      @include bp(mobile-xs) {
        margin: 0 4px;
      }

      span {
        margin-right: 6px;

        &:last-child {
          @include bp (mobile) {
            display: none;
          }
        }
      }

      .icon {
        @include bp (mobile) {
          font-size: 20px;
          margin: 0;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 1);
        opacity: 1;
        color: #bf9c3b;
        width: 240px;

        @include bp(tablet) {
          width: auto;
        }
      }
    }
  }

  .off-services {
    position: absolute;
    z-index: 3;
    color: #fff;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 20px;
    background: rgba(0, 0, 0, .5);
    border-radius: 4px;
    white-space: nowrap;
    font-size: 18px;

    @include bp(mobile) {
      font-size: 15px;
      bottom: 10vh;
    }
  }
}
