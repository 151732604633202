@import "../variables";

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background: #000000;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    @include bp(tablet) {
      flex-direction: column;
    }
  }

  &__block {
      color: #bf9c3b;
      z-index: 2;

      @include bp(tablet) {
        text-align: center;
      }

    &--item {
      display: block;
      color: #ffffff;
      margin-bottom: 10px;
    }
  }

  .social {
    position: relative;
    bottom: unset;
    left: unset;
    width: auto;

    @include bp(tablet) {
      margin-top: 30px;
    }
  }

  .copy {
      bottom: 40px;
      z-index: 0;

      @include bp(tablet) {
        position: relative;
        bottom: 0;
        margin-top: 40px;
      }

      &__text {
          opacity: .7;
          font-size: 14px;
          letter-spacing: 1.2px;
      }
  }
}
