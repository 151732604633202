@import "../variables";

.nav {
  width: 100%;
  height: 100%;

  &__wrapper {
    position: relative;
    padding: 0 25px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 12;
  }
}

.logo {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  height: 135px;
  width: 230px;

  @include bp(desktop-l) {
    width: 170px;
    height: auto;
  }

  &__img {
    object-fit: contain;
    width: 100%;

    @include bp(desktop-ui) {
      height: 110px;
    }
  }
}

.copy {
  position: absolute;
  bottom: 30px;
  width: 100%;
  left: 0;
  padding: 0 25px;

  &__text {
    font-size: 13px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
  }
}

.social {
  position: absolute;
  bottom: 80px;
  width: 100%;
  left: 0;
  padding: 0 25px;

  @include bp(tablet) {
    position: static;
    bottom: unset;
    left: unset;
    padding: 25px 0;
  }

  &__title {
    font-size: 15px;
    text-align: center;
    color: #555555;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  &__item {
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-bottom-color: #232323;
    position: relative;
    top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      a {
        color: #bf9c3b;
      }
    }

    a {
      position: relative;
      top: 25px;
      z-index: 2;
      color: #ffffff;
      transition: all 0.3s;
    }

    &:after {
      content: "";
      position: absolute;
      left: -25px;
      top: 25px;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-top-color: #232323;
    }
  }
}
