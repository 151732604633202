@import "../variables";

.menu {
  width: 100%;

  @include bp(tablet) {
    padding: 25px 0;
    width: 200px;
    margin: 0 auto;
  }

  &__item {
    position: relative;
    display: block;
    text-align: center;
    padding: 12px 0;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;

    @include bp(desktop-l) {
      padding: 10px 0;
    }
    @include bp(desktop-ui) {
      padding: 8px 0;
    }

    @include bp(tablet) {
      padding: 12px 0;
    }

    p {
      color: #8e9599;
      font-size: 18px;
      font-weight: 500;
      transition: all 0.3s;

      @include bp(desktop-ui) {
        font-size: 15px;
      }

      @include bp(tablet) {
        font-size: 17px;
      }
    }

    &.shop {
      p {
        color: #bf9c3b;
        opacity: 0.6;
      }

      &:hover {
        p {
          color: #bf9c3b;
          opacity: 1;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 10px;
      background: #14181b;
      transition: all 0.3s;

      @include bp(tablet) {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 50%;
      margin-top: 1px;
      transform: translateX(-50%);
      height: 2px;
      width: 10px;
      background: #14181b;
      transition: all 0.3s;

      @include bp(tablet) {
        width: 50px;
      }
    }

    &.active {
      p {
        opacity: 1;
        color: #ffffff;
      }
      &:after {
        z-index: 2;
        width: 40px;
        background: #ffffff;

        @include bp(tablet){
          width: 100px;
        }
      }

      &:before {
        width: 40px;
        background: #ffffff; 
      }
    }

    &:hover {
      p {
        opacity: 1;
        color: #b8b8b8;
      }

      &:after {
        z-index: 2;
        width: 25px;
        background: #b8b8b8;
      }

      &:before {
        width: 25px;
        background: #b8b8b8;
      }
    }

    &-sub {
      padding: 10px 0;
      display: block;
      width: 100%;
      text-align: center;
      position: relative;

      p {
        font-size: 15px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 1px;
        background: #e2dfdf;
        transition: all 0.3s;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 1px;
        background: #e2dfdf;
        transition: all 0.3s;
      }
    }
  }
}
