@import "../variables";

.contact {
  position: relative;
  background: #040707;
  height: 100vh;

  @include bp(tablet) {
    height: calc(100vh - 80px);
  }

  &__map {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;

    @include bp(mobile){
      height: calc(100% - 80px);
      top: 80px;
    }

    &--bottom {
      width: 100%;
      position: absolute;
      height: 20vh;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(4, 7, 7, 1) 0%,
        rgba(4, 7, 7, 1) 10%,
        rgba(4, 7, 7, 0) 100%
      );
      z-index: 2;
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      #040707 0%,
      #040707 17%,
      rgba(4, 7, 7, 0.8) 60%,
      rgba(4, 7, 7, 0) 100%
    );
    z-index: 2;
  }

  &__card {
    text-align: center;
    background: black;
    padding: 30px;
    width: 320px;
    border-radius: 6px;
    margin-top: 0px;
    box-shadow: -1px 20px 14px 1px rgba(6, 6, 6, 0.8);
    transition: all 0.3s;

    @include bp(desktop-m) {
      width: 240px;
      padding: 22px;
    }

    @include bp(mobile){
      min-width: 280px;
      margin-bottom: 10px;
      width: 90%;
      padding: 15px;
    }

    &:hover {
      margin-top: -8px;
      box-shadow: -1px 20px 20px 1px rgba(6, 6, 6, 0.9);
      background: #ffffff;

      .contact__card--text p {
        color: #000000;
      }
    }

    &-list {
      width: 1100px;
      margin: 70px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include bp(desktop-m) {
        width: 100%;
        justify-content: space-around;
      }
      @include bp(mobile) {
        flex-direction: column;
        margin: 20px auto;    
      }
    }

    &--title {
      color: #bf9c3b;
      font-size: 21px;
      font-weight: 700;
      margin-bottom: 7px;

      @include bp(desktop-m) {
        font-size: 19px;
      }

      @include bp(mobile) {
        font-size: 18px;
        margin-bottom: 3px;
      }
    }

    &--text {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include bp(mobile) {
        height: auto;
      }

      p {
        color: #e0e0e0;
        font-size: 15px;
        transition: all 0.3s;

        @include bp(desktop-m) {
          font-size: 14px;
        }
      }
    }
  }

  .map {
    &__navigation {
      position: absolute;
      bottom: 15vh;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      z-index: 2;
      background: #ffffff;
      padding: 10px 90px;
      border-radius: 5px;
      transition: all .3s;

      @include bp(mobile) {
        width: 70%;
        justify-content: center;
        min-width: 270px;
        padding: 10px;
      }

      &:hover {
        background: #000000;
        box-shadow: -1px 20px 14px 1px rgba(6, 6, 6, 0.8);

        p, span {
          color: #bf9c3b;
        }
      }

      p {
        color: #040707;
        font-size: 17px;
        margin-left: 10px;
        font-weight: 500;
      }

      span {
        font-size: 18px;
        display: inline-flex;
        color: #040707;
      }
    }
  }
}
