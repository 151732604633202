@import "variables";

@for $i from 9 to 100 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 9 to 100 {
  .font-size-#{$i}-i {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 to 50 {
  .mt-#{$i * 5} {
    margin-top: #{$i * 5}px;
  }
}

@for $i from 0 to 50 {
  .mt-#{$i * 5}-i {
    margin-top: #{$i * 5}px !important;
  }
}

@for $i from 0 to 200 {
  .min-height-#{$i * 5} {
    min-height: #{$i * 5}px;
  }
}

@for $i from 0 to 200 {
  .height-#{$i * 5} {
    height: #{$i * 5}px;
  }
}

@for $i from 0 to 50 {
  .mb-#{$i * 5} {
    margin-bottom: #{$i * 5}px;
  }
}

@for $i from 0 to 50 {
  .mr-#{$i * 5} {
    margin-right: #{$i * 5}px;
  }
}

@for $i from 0 to 50 {
  .mr-#{$i * 5}-i {
    margin-right: #{$i * 5}px !important;
  }
}

@for $i from 1 to 50 {
  .ml-#{$i * 5} {
    margin-left: #{$i * 5}px;
  }
}

@for $i from 1 to 50 {
  .mg-horizontal-#{$i * 5} {
    margin-right: #{$i * 5}px;
    margin-left: #{$i * 5}px;
  }
}

@for $i from 1 to 50 {
  .mg-vertical-#{$i * 5} {
    margin-top: #{$i * 5}px;
    margin-bottom: #{$i * 5}px;
  }
}

.mg-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 0 to 50 {
  .pt-#{$i * 5} {
    padding-top: #{$i * 5}px;
  }
}

@for $i from 0 to 50 {
  .pb-#{$i * 5} {
    padding-bottom: #{$i * 5}px;
  }
}

@for $i from 1 to 50 {
  .pr-#{$i * 5} {
    padding-right: #{$i * 5}px;
  }
}

@for $i from 1 to 50 {
  .pl-#{$i * 5} {
    padding-left: #{$i * 5}px;
  }
}

@for $i from 1 to 50 {
  .pad-horizontal-#{$i * 5} {
    padding-right: #{$i * 5}px;
    padding-left: #{$i * 5}px;
  }
}

@for $i from 1 to 50 {
  .pad-vertical-#{$i * 5} {
    padding-top: #{$i * 5}px;
    padding-bottom: #{$i * 5}px;
  }
}

@for $i from 1 to 60 {
  .space-#{$i * 5} {
    height: #{$i * 5}px;
  }
}

.colored-dark-gray {
  color: #51565b;
}

.colored-purple {
  color: $color-purple;
}

.colored-purple-opacity {
  color: $color-purple-opacity;
}

.colored-purple-i {
  color: $color-purple !important;
}

.colored-blue {
  color: $color-blue;
}

.colored-blue-i {
  color: $color-blue !important;
}

.colored-blue-dark {
  color: $color-blue-dark;
}

.colored-blue-dark-i {
  color: $color-blue-dark !important;
}

.colored-white {
  color: $color-white;
}

.colored-white-i {
  color: $color-white !important;
}

.colored-navy {
  color: $color-navy;
}

.colored-navy-i {
  color: $color-navy !important;
}

.colored-navy-gray-i {
  color: $color-navy-gray !important;
}

.colored-navy-gray {
  color: $color-navy-gray;
}

.colored-gray {
  color: $color-gray;
}

.colored-yellow {
  color: $color-yellow;
}

.colored-yellow-i {
  color: $color-yellow !important;
}

.colored-gray-i {
  color: $color-gray !important;
}

.colored-text-gray {
  color: $color-text-gray;
}

.colored-text-gray-i {
  color: $color-text-gray !important;
}

.colored-light-gray {
  color: $color-light-gray;
}

.colored-light-gray-i {
  color: $color-light-gray !important;
}

.colored-red-i {
  color: $color-red !important;
}

.colored-red {
  color: $color-red;
}

.colored-dark-red {
  color: $color-dark-red;
}

.colored-dark-red-i {
  color: $color-dark-red !important;
}

.colored-light-red {
  color: $color-light-red;
}

.colored-light-red-i {
  color: $color-light-red !important;
}

.text-italic {
  font-style: italic !important;
}

.icon-font {
  display: inline-flex !important;
}

.clear {
  clear: both;
}

.clear-float {
  float: none;
  clear: both;
}

.textarea {
  height: 200px;
}

.img-contain {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inline-flex {
  display: inline-flex !important;
  align-items: center;
}

.img-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.underline {
  border-bottom: 1px solid;
}

.overflow-hidden {
  overflow: hidden;
}

.img-radius {
  border-radius: 6px;
}

.img-shadow {
  //box-shadow: 0 13px 24px 0 rgba(19,29,51,0.23);
  box-shadow: 0 13px 24px 0 rgba(41, 53, 108, 0.28);
}

.img-box {
  border-radius: 6px;
  box-shadow: 0 13px 24px 0 rgba(41, 53, 108, 0.28);
}

.img-white {
  filter: brightness(100);
}

.box-shadow {
  box-shadow: 0 13px 24px 0 rgba(41, 53, 108, 0.28);
}

.shadow-none {
  box-shadow: none !important;
}

.shadow {
  box-shadow: 0 13px 24px 0 rgba(41, 53, 108, 0.28);
}

.box-radius {
  border-radius: 6px;
}

.white-box {
  background: $color-white;
  box-shadow: 0px 7px 16px 0px rgba(41, 53, 108, 0.25);
  border-radius: 6px;
}

.font-300 {
  font-weight: 300;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-flex-a-center {
  display: flex;
  align-items: center;
}

.a-center {
  align-items: center;
}

.justify-spacebetween {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.fixed-top {
  position: fixed;
  top: 0;
}

.position-static-i {
  position: static !important;
}

.position-relative-i {
  position: relative !important;
}

.position-absolute-i {
  position: absolute !important;
}

.position-fixed-i {
  position: fixed !important;
}

.position-sticky-i {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 111;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 111;
}

.full-width {
  width: 100%;
}

//
//.border {
//  border: 1px solid #dde3eb;
//}
//
//.border-bottom {
//  border-bottom: 1px solid #dde3eb;
//}
//
//.border-top {
//  border-top: 1px solid #dde3eb;
//}
//
//.border-left {
//  border-left: 1px solid #dde3eb;
//}
//
//.border-right {
//  border-right: 1px solid #dde3eb;
//}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.height-auto {
  height: auto;
}

.height-auto-i {
  height: auto !important;
}

.hr {
  width: 100%;
  height: 1px;
  background: #dde3eb;
  clear: both;
}

.hr-vertical {
  height: 100%;
  width: 1px;
  background: #dde3eb;
  clear: both;
}

.hr-secondary {
  width: 100%;
  height: 1px;
  //background-image: linear-gradient(to left, rgba(228,237,245,0) 0%, rgba(161,184,208,0.65) 50%, rgba(228,237,245,0) 100%);
  background-image: linear-gradient(to left, rgba(228, 237, 245, 0) 0%, #c1d1e2 50%, rgba(242, 247, 253, 0) 100%);
  box-shadow: 0px 0px 16px 0px rgba(41, 53, 108, 0.2);
}

.hr-bottom {
  border-bottom: 1px solid #dde3eb;
}

.hr-top {
  border-top: 1px solid #dde3eb;
}

.hr-top-secondary {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 1px;
    max-width: 1400px;
    transform: translateX(-50%);
    //background-image: linear-gradient(to left, rgba(228,237,245,0) 0%, rgba(161,184,208,0.65) 50%, rgba(228,237,245,0) 100%);
    background-image: linear-gradient(to left, rgba(228, 237, 245, 0) 0%, #c1d1e2 50%, rgba(242, 247, 253, 0) 100%);
    box-shadow: 0px 0px 16px 0px rgba(41, 53, 108, 0.2);
  }
}

.hr-bottom-secondary {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 1px;
    max-width: 1400px;
    transform: translateX(-50%);
    //background-image: linear-gradient(to left, rgba(228,237,245,0) 0%, rgba(161,184,208,0.65) 50%, rgba(228,237,245,0) 100%);
    background-image: linear-gradient(to left, rgba(228, 237, 245, 0) 0%, #c1d1e2 50%, rgba(242, 247, 253, 0) 100%);
    box-shadow: 0px 0px 16px 0px rgba(41, 53, 108, 0.2);
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.5 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-1 {
  opacity: 1 !important;
}
