@import "../variables";

.about-us {
  .card {
    width: calc((100% / 3) - 10px);
    margin-right: calc(30px / 2);
    margin-bottom: calc(30px / 2);
    margin-left: 0;
    transform: translateY(100px);
    opacity: 0;

    @include bp(desktop-ui) {
      width: calc((100% / 2) - 10px);
      margin-right: calc(20px / 2);
      margin-bottom: calc(20px / 2);
    }

    @include bp(mobile) {
      width: 100%;
      margin-right: 0;
    }

    &:nth-child(2n + 2) {
      margin-right: 0;
    }

    &__list {
      justify-content: center;
      justify-content: center;
      padding: 6% 0;

      @include bp(desktop-ui) {
        padding: 4% 0;
      }
      @include bp(mobile) {
        flex-direction: column;
      }
    }

    &__image {
      transform: scale(1.1);
      transition: all 0.3s;
    }

    &:hover {
      .card {
        &__image {
          transform: scale(1);
        }
      }
    }
  }
  &.active {
    .card {
      transform: translateY(0px);
      opacity: 1;
    }
    @for $i from 1 to 20 {
      .card:nth-child(#{$i}) {
        transition-delay: $i * 0.12s;
      }
    }
  }
}
.icon-facebook2:before {
  content: "\ea91";
}
