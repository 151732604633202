@font-face {
  font-family: 'DIN Pro Cond';
  src: url('../fonts/DINPro-CondensedBlack.woff2') format('woff2'),
  url('../fonts/DINPro-CondensedBlack.woff') format('woff'),
  url('../fonts/DINPro-CondensedBlack.svg#DINPro-CondensedBlack') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: url('../fonts/DINPro-CondensedMedium.woff2') format('woff2'),
  url('../fonts/DINPro-CondensedMedium.woff') format('woff'),
  url('../fonts/DINPro-CondensedMedium.svg#DINPro-CondensedMedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: url('../fonts/DINPro-CondensedRegular.woff2') format('woff2'),
  url('../fonts/DINPro-CondensedRegular.woff') format('woff'),
  url('../fonts/DINPro-CondensedRegular.svg#DINPro-CondensedRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: url('../fonts/DINPro-CondensedBold.woff2') format('woff2'),
  url('../fonts/DINPro-CondensedBold.woff') format('woff'),
  url('../fonts/DINPro-CondensedBold.svg#DINPro-CondensedBold') format('svg');
  font-weight: 700;
  font-style: normal;
}



@font-face {
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Black.woff2') format('woff2'),
  url('../fonts/DINPro-Black.woff') format('woff'),
  url('../fonts/DINPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Bold.woff2') format('woff2'),
  url('../fonts/DINPro-Bold.woff') format('woff'),
  url('../fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Medium.woff2') format('woff2'),
  url('../fonts/DINPro-Medium.woff') format('woff'),
  url('../fonts/DINPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Regular.woff2') format('woff2'),
  url('../fonts/DINPro-Regular.woff') format('woff'),
  url('../fonts/DINPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Light.woff2') format('woff2'),
  url('../fonts/DINPro-Light.woff') format('woff'),
  url('../fonts/DINPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Barlow:400,500,700');




