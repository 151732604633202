@import "../variables";

.page {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    transition: all 0.3s;

    &.active {
      opacity: 0.2;
      pointer-events: auto;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 320px;
    width: calc(100% - 320px);

    @include bp(desktop-ui) {
      left: 270px;
      width: calc(100% - 270px);
    }

    @include bp(tablet) {
      left: 0;
      width: 100%;
    }
  }

  &__left {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh;
    background: #040707;
    z-index: 11;

    @include bp(desktop-ui) {
      width: 270px;
    }

    @include bp(tablet) {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 15px solid #ffffff;
      border-bottom: 10px solid transparent;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;
    }

    &.open {
      &:before {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &-sub {
    padding: 70px 100px;

    @include bp(desktop-m) {
      padding: 60px 60px;
    }

    @include bp(desktop-ui) {
      padding: 50px 40px;
    }

    @include bp(tablet) {
      padding: 110px 25px 50px 25px;
    }
  }

  &__title {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;
    z-index: 3;

    @include bp(desktop-ui) {
      margin-bottom: 20px;
    }

    @include bp(tablet-small) {
      margin-bottom: 40px;
    }

    @include bp(mobile) {
      margin-bottom: 30px;
    }

    @include bp(mobile-small) {
      margin-bottom: 15px;
    }

    p {
      position: relative;
      display: inline;
      background: #ffffff;
      color: rgb(191, 156, 59);
      padding: 0 15px;
      font-weight: 700;
      font-size: 30px;
      line-height: 1;
      z-index: 2;

      @include bp(desktop-ui) {
        font-size: 25px;
      }

      @include bp(tablet-small) {
        font-size: 23px;
        padding: 0 12px;
      }

      @include bp(mobile-small) {
        font-size: 21px;
      }
    }

    &--line {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30vw;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(191, 156, 59, 0) 0%,
        rgba(191, 156, 59, 1) 50%,
        rgba(191, 156, 59, 0) 100%
      );

      @include bp(tablet-small) {
        width: 320px;
      }
    }
  }

  &-desc {
    &__title {
      padding-bottom: 5px;
      font-size: 21px;
    }

    &__txt {
      padding-bottom: 10px;
    }
  }

  &__slider {
    &--fw {
      position: relative;
    }

    &_dots {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 60px);
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 3;
    }

    &_img {
      width: 100%;
      height: 760px;
      object-fit: cover;
      opacity: 0;
      pointer-events: none;
      -webkit-filter: blur(0.6);
      transition: all 0.6s;
    }

    &_item {
      &.swiper-slide-active {
        .page__slider_img {
          opacity: 1;
          pointer-events: auto;
          -webkit-filter: blur(1);
        }
      }
    }
  }

  &-elm {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: 0;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.3s;

    @include bp(tablet) {
      height: calc(100vh - 80px);
      top: 80px;
    }

    &.active {
      opacity: 1;
      z-index: 1;
      pointer-events: auto;
    }

    &.services {
      .card {
        @include bp(desktop-l) {
          width: calc((100% / 3) - 10px);
          margin-right: calc(30px / 2);
          margin-bottom: calc(54px / 2);
        }
        @include bp(mobile) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
        &:nth-child(4n + 4) {
          @include bp(desktop-l) {
            margin-right: calc(30px / 2);
          }
          @include bp(mobile) {
            margin-right: 0;
          }
        }

        &:nth-child(3n + 3) {
          @include bp(desktop-l) {
            margin-right: 0;
          }
        }

        &__title {
          margin-bottom: 10px;
          font-size: 19px;
        }

        &__image {
          @include bp(desktop-m) {
            height: 170px;
          }
        }
      }

      &.active {
        overflow: scroll;
        height: auto;
      }
    }

    &.about-us {
      &.active {
        overflow: scroll;
        height: auto;
      }
    }
  }
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: #040707;
  z-index: -11;
  opacity: 0;
  pointer-events: none;

  &.active {
    display: flex;
    z-index: 333;
    opacity: 1;
    pointer-events: auto;
  }

  &__text {
    color: #bf9c3b;
    font-size: 3em;
    line-height: 1;
    margin-top: 25px;

    @include bp(tablet) {
      font-size: 2.4em;
    }
    @include bp(mobile) {
      font-size: 1.4em;
    }

    &-sm {
      font-size: 30px;
      color: #ffffff;
      opacity: 1;
      margin-top: 30px;
      letter-spacing: 2.3px;
    }
  }

  &__container {
    text-align: center;
    margin-bottom: 50px;
  }

  &__logo {
    img {
      width: 60px;
      height: 60px;
    }
  }
}

.brand {
  &-title {
    font-size: 23px;

    @include bp(mobile) {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
  &__list {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;

    @include bp(tablet) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  &__elm {
    padding: 6px 14px;
    line-height: 35px;
    background: #efeeee;
    border-radius: 4px;
    margin: 6px;

    @include bp(tablet) {
      margin: 6px;
    }

    img {
      height: 100%;
      max-height: 30px;
      object-fit: cover;

      @include bp(tablet) {
        max-height: 20px;
      }
    }
  }
}
