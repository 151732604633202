@import "variables";

.form {
  margin-top: 10px;
  margin-bottom: 30px;

  &__page {
    @include bp(mobile) {
      padding: 0;
    }
  }
  &__elm {
    margin-bottom: 20px;

    &--text {
      line-height: 1;
      margin-bottom: 10px;
      font-size: 17px;
    }
  }

  &__input {
    border: 2px solid #eaecec;
    border-radius: 4px;
    transition: all 0.3s;

    &:focus {
      border-color: rgb(191, 156, 59);
    }

    &::placeholder {
      font-size: 14px;
    }
  }
  &__button {
    display: inline-block;
    width: auto;
    padding: 0 30px;
    background: #bf9c3b;
    color: #ffffff;
    border-radius: 4px;
    font-size: 19px;
    height: 45px;
    font-weight: 500;

    @include bp(mobile) {
      width: 100%;
    }
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    &--elm {
      display: flex;
      align-items: center;
      /* width: 183px; */
      white-space: nowrap;
      margin-bottom: 15px;
      padding: 6px 12px;
      background: #eaecec;
      margin-right: 15px;
      border-radius: 4px;

      @include bp(mobile) {
        padding: 6px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      input {
        height: 20px;
        width: 20px;
        margin-right: 5px;
      }
    }
  }

  &__flex {
    display: flex;

    @include bp(mobile) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &--elm {
      @include bp(mobile) {
        width: 100%;
        flex: auto;
        max-width: unset;
        padding-left: 0 !important;
      }

      &:last-child {
        @include bp(mobile) {
          margin-top: 10px;
        }
      }
    }
  }

  &__off-services {
    color: rgb(245, 130, 130);
  }

  select {
    width: 100%;
    height: 100%;
    border: 2px solid #eaecec;
    border-radius: 4px;
    background: transparent;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  height: 50px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 45px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px !important;
}

.select2-container--default .select2-selection--single {
  border: 2px solid #eaecec !important;
  height: 50px !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 15px !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;

  li {
    margin-top: 0 !important;
    margin-right: 10px !important;
  }
}

.select2-container--default .select2-search--inline .select2-search__field {
  margin-top: 0 !important;

  &::placeholder {
    font-size: 14px !important;
  }
}

select2 select2-container select2-container--default {
  width: 100%;
}
