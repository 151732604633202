@mixin bp($point) {
  @if $point == desktop-xl {
    @media (max-width: 1920px) {
      @content;
    }
  } @else if $point == r-desktop-xl {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-l {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point == r-desktop-l {
    @media (min-width: 1600px) {
      @content;
    }
  }  @else if $point == desktop-m {
    @media (max-width: 1400px) {
      @content;
    }
  } @else if $point == r-desktop-m {
    @media (min-width: 1400px) {
      @content;
    }
  }@else if $point == desktop-ui {
    @media (max-width: 1200px) {
      @content;
    }
  }@else if $point == desktop {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == tablet-small {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point == r-desktop {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == r-tablet {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == r-tablet-small {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == r-mobile {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == nav {
    @media (max-width: 900px) {
      @content;
    }
  } @else if $point == r-nav {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $point == mobile-small {
    @media (max-width: 480px) {
      @content;
    }
  }@else if $point == mobile-xs {
    @media (max-width: 370px) {
      @content;
    }
  }
}

$padding-container: 8vw;
$padding-container-l: 50px;
$padding-container-m: 25px;
$padding-container-s: 25px;
$padding-container-xs: 25px;
$padding-container-xxs: 15px;


$color-purple : #7569de;
$color-purple-opacity : #796de2;
$color-gray : #788489;
$color-text-gray : #889fbc;
$color-light-gray : #95a5b3;


$color-blue : #4dabfc;
$color-blue-dark : #3d94e2;
//$color-green : #2bc18c;
$color-green : #9ecb45;
$color-white : #ffffff;
//$color-navy : #21325a;
//$color-navy : #322e57;
$color-navy : #37464d;
$color-navy-gray : #687282;
$color-error : #eb4d4b;
$color-red : #e8505a;
$color-dark-red : #cb527d;
$color-light-red : #ff4b64;
$color-orange : #ff7b46;
$color-orange-light : #fa7875;
$color-yellow : #bf9c3b;



$event-info: #1e90ff;
$event-important: #ad2121;
$event-warning: #e3bc08;
$event-inverse: #1b1b1b;
$event-success: #006400;
$event-special: #800080;