@import "../variables";

.card {
  width: calc((100% / 4) - 10px);
  margin-right: calc(40px / 3);
  margin-bottom: calc(60px / 3);
  border: none;
  z-index: 1;
  transition: all 0.3s;

  @include bp(desktop-m) {
    width: calc((100% / 3) - 8px);
    margin-right: calc(24px / 2);
  }

  @include bp(tablet) {
    width: calc((100% / 2) - 8px);
    margin-right: calc(16px / 2);
  }

  @include bp(mobile) {
    width: 100%;
    margin-right: 0;
  }

  &:hover {
    .card__wrapper {
      box-shadow: -1px 5px 15px 1px rgba(114, 114, 114, 0.5);
      transform: translateY(-10px);
    }
  }

  &:nth-child(4n + 4) {
    margin-right: 0;

    @include bp(desktop-m) {
      margin-right: calc(24px / 2);
    }
  }

  &:nth-child(3n + 3) {
    @include bp(desktop-m) {
      margin-right: 0;
    }

    @include bp(tablet) {
      margin-right: calc(16px / 2);
    }

    @include bp(mobile) {
      margin-right: 0;
    }
  }

  &:nth-child(2n + 2) {
    @include bp(tablet) {
      margin-right: 0;
    }
  }

  &__wrapper {
    transform: translateY(10px);
    box-shadow: -1px -2px 15px 1px rgba(114, 114, 114, 0.2);
    transition: all 0.3s;
    position: relative;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__image {
    width: 100%;
    height: 200px;
    object-fit: cover;

    @include bp(desktop) {
      height: 150px;
    }
  }

  &__desc {
    position: relative;
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp(desktop) {
      height: 62px;
      padding: 0 10px;
    }

    @include bp(mobile) {
      height: auto;
      padding: 15px 10px;
    }

    &:before {
      content: "";
      position: absolute;
      top: -40px;
      width: 100%;
      height: 40px;
      left: 0;
      background: linear-gradient(
        0deg,
        white 0%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &__title {
    margin-bottom: 0;
    color: rgb(191, 156, 59);
    font-size: 17px;
    line-height: 1.4;
    text-align: center;
    font-weight: 500;

    @include bp(desktop) {
      font-size: 15px;
    }
  }

  &__text {
    font-size: 17px;
  }

  &-link {
    .card__wrapper {
      border-radius: 5px;
      overflow: hidden;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:hover {
        transform: translateY(0px);
        box-shadow: -1px 5px 15px 1px rgba(114, 114, 114, 0.8);
        z-index: 2;
      }
    }
    .card {
      &__wrapper {
        position: relative;
      }

      &__image {
        height: 180px;

        @include bp(desktop-ui) {
          height: 160px;
        }
      }

      &__desc {
        position: absolute;
        padding: inherit;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.35);
        transition: all 0.3s;
      }

      &__title {
        margin-bottom: 0;
        color: #fefefe;
        font-size: 33px;
      }
    }
  }

  &-banner {
    display: block;
    position: relative;
    margin-bottom: 20px;

    .card {
      &__desc {
        position: absolute;
        top: 50%;
        left: 0;
        height: 100%;
        padding: 0 50px;
        transform: translateY(-50%);
        width: 100%;
        justify-content: flex-start;
        background: rgba(4, 7, 7, .6);
        transition: all .3s;

        &:hover {
          background: rgba(4, 7, 7, .8);
        }

        &:before {
          display: none;
        }

        p {
          font-size: 3.9em !important;
          color: #ffffff;
          font-family: cursive;
          opacity: .85;

          @include bp(tablet-small) {
            font-size: 2.4em !important;
          }

          @include bp(mobile-small) {
            font-size: 2.1em !important;
          }
        }
      }

      &__wrapper {
        border-radius: 5px;
        overflow: hidden;
      }

      &__title {
        margin-bottom: 0 !important;
      }

      &__image {
        height: 360px !important;

        @include bp(tablet-small) {
          height: 280px !important;
        }

        @include bp(mobile-small) {
          height: 220px !important;
        }
      }
    }
  }
}

.services {
  .card {
    transform: translateY(100px);
    opacity: 0;
  }
  &.active {
    .card {
      transform: translateY(10px);
      opacity: 1;
    }
    @for $i from 1 to 20 {
      .card:nth-child(#{$i}) {
        transition-delay: $i * 0.12s;
      }
    }
  }
}
