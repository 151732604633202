@import "variables";

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
    font-family: "Barlow", sans-serif;
  }
}

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  overflow-x: hidden !important;
  position: relative;
}

body {
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre,
fieldset,
ul,
ol,
menu,
form {
  margin: 0;
}

p {
  margin-top: 0;
  font-size: 17px;
  font-weight: 400;
  color: #60696c;
  margin-bottom: 0;
  font-family: "Barlow", sans-serif;
  line-height: 1.4em;

  @include bp(mobile) {
    font-size: 16px;
    margin-bottom: 0;
  }
}

button,
fieldset,
iframe {
  border: 0;
}

fieldset,
ul,
ol,
button,
menu {
  padding: 0;
}

ol,
ul {
  list-style: none;
}

textarea {
  resize: vertical;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  padding: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  color: #bf9c3b;
}

a:focus {
  outline: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #bf9c3b;
  font-weight: 700;
  margin-bottom: 15px;
}

.hidden {
  display: none !important;
}

.transition-5 {
  transition: all 0.5s;
}

.fw {
  width: 100%;
}

body {
  font-family: "DIN Pro Cond", sans-serif;
  background: #ffffff;
  color: #60696c;
  //background: #171e25;
  //overflow: hidden;
  //height: 100vh;
}

.scrollbar-thumb-y {
  background: #bf9c3b !important;
  border-radius: 20px !important;
  left: -2px !important;
  width: 5px !important;
}

.scrollbar-track-y {
  opacity: 1 !important;
  right: 5px !important;
  width: 1px !important;
  background: #dadcdc !important;
}

.scrollbar-thumb-x {
  height: 5px !important;
  background: #bf9c3b !important;
  border-radius: 4px !important;
  top: -3px !important;
}

.scrollbar-track-x {
  bottom: 7px !important;
  height: 1px !important;
  background: #dadcdc !important;
  opacity: 1 !important;
}

.pad-left {
  padding-left: $padding-container;

  @include bp(desktop-xl) {
    padding-left: $padding-container-l;
  }
}

.pad-right {
  padding-right: $padding-container;

  @include bp(desktop-xl) {
    padding-right: $padding-container-l;
  }
}

.icon-font {
  display: inline-flex !important;
}

.clear {
  clear: both;
}

.clear-float {
  float: none;
}

.gray-bg {
  background: #f0f0f0;
}

.white-bg {
  background: #fff;
}

.border-left-dark {
  border-left: 1px solid #2d343a;
}

.border-right-dark {
  border-right: 1px solid #2d343a;
}

.slick-custom-arrow {
}

.font-text {
  font-family: "Barlow", sans-serif;
}

.slider-arrows {
  display: flex;

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    padding: 5px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    position: relative;
    background: #fff;
    border: 1px solid #717e84;
    color: #42535a;
    font-size: 18px;

    @include bp(mobile) {
      width: 35px;
      height: 35px;
      font-size: 15px;
      border: 1px solid #717e84;
    }

    @include bp(r-tablet) {
      &:hover {
        background: #bf9c3b !important;
        color: #fff !important;
        //border-color: #50555b;
      }
    }

    &.prev {
      //margin-left: -1px;

      .icon {
        //filter: drop-shadow(40px 0px 0px currentColor);
        transition: transform 0.3s;

        &--hover {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(40px, -50%);

          @include bp(mobile) {
            transform: translate(35px, -50%);
          }
        }
      }

      @include bp(r-tablet) {
        &:hover {
          .icon {
            transform: translateX(-40px);

            @include bp(mobile) {
              transform: translateX(-35px);
            }

            &--hover {
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    &.next {
      //margin-left: -1px;

      .icon {
        //filter: drop-shadow(-40px 0px 0px currentColor);
        transition: transform 0.3s;

        &--hover {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-40px, -50%);

          @include bp(mobile) {
            transform: translate(-35px, -50%);
          }
        }
      }

      @include bp(r-tablet) {
        &:hover {
          .icon {
            transform: translateX(40px);

            //@include bp(mobile) {
            //  transform: translateX(35px);
            //}

            &--hover {
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column;

    .slider-arrows__item {
      font-size: 16px;
      margin: 0;

      &.next {
        margin-left: 0;
        margin-top: -2px;
      }

      &.prev {
        //margin-left: -1px;

        .icon {
          //filter: drop-shadow(0px 40px 0px currentColor);
          transition: transform 0.3s;

          &--hover {
            transform: translate(-50%, 40px);

            @include bp(mobile) {
              transform: translate(-50%, 35px);
            }
          }
        }

        @include bp(r-tablet) {
          &:hover {
            .icon {
              transform: translateY(-40px);

              @include bp(mobile) {
                transform: translateY(-35px);
              }

              &--hover {
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }

      &.next {
        //margin-left: -1px;

        .icon {
          //filter: drop-shadow(0px -40px 0px currentColor);
          transition: transform 0.3s;

          &--hover {
            transform: translate(-50%, -40px);

            @include bp(mobile) {
              transform: translate(-50%, -35px);
            }
          }
        }

        @include bp(r-tablet) {
          &:hover {
            .icon {
              transform: translateY(40px);

              @include bp(mobile) {
                transform: translateY(35px);
              }

              &--hover {
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }
}


button {
  outline: none;
  background: none;
  border: 2px solid #687282;
  box-shadow: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
}

form {
  width: 100%;
}

input,
textarea {
  display: block;
  width: 100%;
  background: none;
  box-shadow: none;
  border: none;
  height: 50px;
  padding: 0 15px;
  font-size: 18px;
  color: #8b9094;
  resize: none;
  transition: all 0.5s;
  font-family: "Barlow", sans-serif;

  &:focus {
    outline: none;

    &::placeholder {
      opacity: 0;
      transform: scale(0.9);
    }
  }

  &::placeholder {
    color: #a4a7aa;
    font-family: "DIN Pro Cond", sans-serif;
    font-size: 20px;
    opacity: 1;
    transform-origin: 0 50%;
    transition: all 0.3s;
  }
}

.pad0 {
  padding: 0;
}

.pad0-i {
  padding: 0 !important;
}

.custom-container {
  padding-right: $padding-container-l;
  padding-left: $padding-container-l;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include bp(desktop-l) {
    max-width: 1400px;
  }

  @include bp(desktop) {
    max-width: inherit;
  }

  @include bp(tablet) {
    padding-right: $padding-container-s;
    padding-left: $padding-container-s;
    //max-width: 800px;
  }

  @include bp(tablet-small) {
    //max-width: 650px;
  }

  @include bp(mobile) {
    padding-right: $padding-container-xs;
    padding-left: $padding-container-xs;
  }

  @include bp(mobile-small) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media screen and (max-width: 360px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.custom-container-pad {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 22;

  &:hover {
    .play-icon {
      border: 18px solid rgba(255, 255, 255, 0.35);
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 25px solid rgba(255, 255, 255, 0.15);
    transition: all ease-in-out 0.3s;

    @include bp(desktop-l) {
      width: 130px;
      height: 130px;
      border: 20px solid rgba(255, 255, 255, 0.15);
    }

    @include bp(desktop-l) {
      width: 100px;
      height: 100px;
      border: 14px solid rgba(255, 255, 255, 0.15);
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #ffffff;
    }

    &:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      margin-left: 6px;
      border-top: 15px solid transparent;
      border-left: 22px solid #b99c1a;
      border-bottom: 15px solid transparent;
    }
  }
}

.slider__dots {
  margin-top: -50px;
}

.normal-slider-dots {
  margin-left: 50%;
  z-index: 3;
  display: inline-flex;
  height: 10px;
  align-items: center;
  margin-top: 20px;
  transform: translateX(-50%);

  .swiper-pagination-bullet {
    display: block;
    background: #bcbfc3;
    opacity: 1;
    height: 3px;
    width: 40px;
    margin: 0 6px;
    border-radius: 0;
    outline: none !important;
    transition: all 0.5s;

    @include bp(desktop) {
      width: 35px;
      margin: 0 5px;
    }

    &-active {
      background: #b99c1a;
      width: 80px;
      transform: scaleY(2);

      @include bp(desktop) {
        width: 70px;
      }
    }
  }
}

.swiper-pagination-bullet {
  outline: none;

  &:focus {
    outline: none;
  }
}

.italik {
  font-style: italic;
}

.message {
  text-align: center;
  margin-top: 25px;

  @include bp(desktop-l) {
    margin-top: 15px;
  }

  p {
    color: #fdfdfd;
    font-size: 17px;
  }
}