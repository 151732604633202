@import "../variables";

.page-home {
  .gallery {
    width: calc(100vw - 320px);
    height: 100vh;

    @include bp(desktop-ui) {
      width: calc(100vw - 270px);
    }
    @include bp(tablet) {
      width: 100%;
      height: 0;
    }

    &.active {
      @include bp(tablet) {
        height: auto;
      }
    }

    &__inner {
      display: flex;

      @include bp(tablet) {
        flex-direction: column-reverse;
        top: 0;
        position: relative;
      }

      &--elm {
        width: 50%;
        position: relative;
        top: 0;
        height: 100vh;

        @include bp(tablet) {
          width: 100%;
          height: 50vh;
        }
      }
    }

    .page {
      &__slider {
        &_img {
          height: 100vh;

          @include bp(tablet) {
            height: 60vh;
          }
        }
      }
    }

    &__photo {
      &_overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 2;

        &:hover {
          &:before {
            opacity: 0.4;
          }

          .gallery__photo--link {
            background: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: 20px;
          left: 20px;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          border: 2px solid white;
          pointer-events: none;
          opacity: 0.15;
          transition: all 0.3s;
        }
      }

      &--link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 1.5em;
        font-weight: 700;
        border: 2px solid #fff;
        border-radius: 6px;
        padding: 8px 28px;
        background: transparent;
        opacity: 0.5;
        transition: all 0.3s;

        @include bp(mobile-small) {
          background: none;
          flex-direction: column;
          border: none;
          font-size: 1.2em;
        }

        .icon {
          line-height: 1;
          margin-right: 15px;
        }
      }
    }

    .video-bg {
      width: 100%;

      &__overlay {
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &:hover {
          &:before {
            opacity: 0.4;
          }

          .video-bg--link {
            background: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: 20px;
          left: 20px;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          border: 2px solid white;
          pointer-events: none;
          opacity: 0.15;
          transition: all 0.3s;
        }
      }

      &--link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 1.5em;
        font-weight: 700;
        border: 2px solid #fff;
        border-radius: 6px;
        padding: 8px 28px;
        background: transparent;
        opacity: 0.5;
        transition: all 0.3s;

        @include bp(mobile-small) {
          background: none;
          flex-direction: column;
          border: none;
          font-size: 1.2em;
        }

        .icon {
          line-height: 1;
          margin-right: 15px;
        }
      }
    }

    &__mobile {
      display: none;
      height: 100%;

      @include bp(tablet) {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.album {
  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__col {
    width: calc((100% / 4) - 10px);
    margin-right: calc(40px / 3);
    display: inline-block;

    @include bp(tablet-small) {
      width: calc((100% / 2) - 6px);
      margin-right: 12px;
    }

    @include bp(mobile-small) {
      width: calc((100% / 2) - 4px);
      margin-right: 8px;
    }

    &:nth-child(2n + 2) {
      @include bp(tablet-small) {
        margin-right: 0;
      }
    }
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }

  &__images {
    &__item {
      margin-bottom: calc(40px / 3);
      display: inline-block;

      @include bp(tablet-small) {
        margin-bottom: 12px;
      }

      @include bp(mobile-small) {
        margin-bottom: 8px;
      }
    }
  }

  &__iframe {
    width: 32%;
    height: 280px;
    display: inline-block;
    margin-bottom: 20px;

    @include bp(desktop) {
      width: 49%;
    }
    @include bp(tablet) {
      height: 240px;
    }
    @include bp(tablet-small) {
      height: 200px;
    }
    @include bp(tablet-small) {
      width: 100%;
      height: 200px;
    }
  }
}
