.video-bg {
    height: 100vh;
    object-fit: cover;
    width: calc(100vw - 320px);

    @include bp(tablet) {
      height: 100%;
      width: 100vw;
    }

    &.hero {
      @include bp(tablet) {
        height: calc(100vh - 80px);
      }
    }
  
    &__block {
      z-index: 1;
      display: flex;
    }
  
    &__overlay {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(4, 7, 7, .3);
      z-index: 1;

      &.active {
        background: rgba(4, 7, 7, 1);
        z-index: 2;
      }
    }
  }
  