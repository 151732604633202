@import "../variables";

.article {
  &-page {
    .news {
      &__list {
        justify-content: flex-start;
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;

    @include bp(tablet) {
      flex-direction: column-reverse;
    }
  }
  &__image {
    width: 40%;
    flex-shrink: 0;
    box-shadow: -3px 8px 21px 0px rgba(0, 0, 0, 0.32);
    border-radius: 6px;

    @include bp(tablet) {
      width: 650px;
      margin: 0 auto;
      margin-bottom: 20px;
      max-height: unset;
      object-fit: cover;
    }

    @include bp(tablet-small) {
      width: 100%;
    }

    .album {
      &__col {
        width: 100%;
        margin: 0;
      }

      &__images {
        &__item {
          margin-bottom: 0;
          display: none;
          border-radius: 5px;
          overflow: hidden;

          &:first-child {
            display: block;
          }

          img {
            width: 100%;
            height: 450px;
            object-fit: cover;

            @include bp(tablet) {
              height: auto;
            }
          }
        }
      }
    }
  }

  &__desc {
    width: 60%;
    flex-shrink: 0;
    padding-right: 50px;

    @include bp(tablet) {
      width: 650px;
      padding-right: 0;
      margin: 20px auto;
    }

    @include bp(tablet-small) {
      width: 100%;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 25px;

    @include bp(desktop) {
      font-size: 23px;
      margin-bottom: 18px;
    }
  }

  &__text {
    margin-bottom: 10px;
    font-size: 17px;

    @include bp(desktop) {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}
