@import "../../variables";

.subpage {
  min-height: 100vh;

  .wrapper {
    padding-top: 50px;
    padding-bottom: 150px;

    @include bp(mobile) {
      padding-top: 35px;
      padding-bottom: 100px;
    }

    @include bp(mobile) {
      padding-top: 25px;
      padding-bottom: 80px;
    }
  }

  &.form {
    .footer {
      position: absolute;

      @include bp(mobile) {
        position: relative;
      }
    }

    .message {
      width: 420px;
      margin: 100px auto;
      height: 140px;
      border-radius: 3px;
      background: #eff3ef;
      box-shadow: 0px 8px 19px 0px rgba(107, 106, 106, 0.3);
      padding: 20px;
      display: flex;
      align-items: center;

      @include bp(mobile) {
        width: auto;
        margin: 0px;
        height: 90px;
      }

      &__text {
        text-align: center;
        display: block;
        width: 100%;
        font-size: 19px;
        font-weight: 500;
        color: #5f5e5e;
        line-height: 1;
      }
    }
  }
}

.news {
  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;

  &__wrp {
    display: flex;
    align-items: center;
  }
  &__prev {
    display: block;
  }
  &__next {
    display: block;
  }
  &__elm {
    line-height: 1;
    padding: 10px 15px;
    background: #fafafa;
    border-radius: 22px;
    color: #000000;
    margin: 0 4px;

    &.active {
      border: 2px solid #bba771;
      background: #f1f1f1;
    }
    @include bp(mobile) {
      padding: 6px 12px;
    }
  }
  &__arrow {
    display: block;
    padding: 10px 23px;
    line-height: 1;
    background: #f1f1f1;
    border-radius: 5px;
    margin: 0 15px;

    &.passive {
      opacity: 0.4;
      pointer-events: none;
    }

    @include bp(mobile) {
      margin: 0 6px;
      padding: 8px 12px;
    }
  }
}

.back-top {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 33;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  padding: 10px 15px;
  line-height: 1;
  transform: rotate(-90deg) translateX(0);
  font-size: 20px;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: -3px -2px 22px 5px rgba(123, 122, 122, 0.2);
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: rotate(-90deg) translateX(4px);
  }
}

.shop {
  &-page {
    padding: 50px 30px;
  }

  &__wrp {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &__icon {
    font-size: 4em;
    line-height: 1;
    display: inline-block;
    padding: 40px;
    border-radius: 50%;
    background: #bf9c3b;
    color: #ffffff;
    box-shadow: -2px 20px 40px 0 rgba(140, 112, 35, 0.5);
  }

  &__text {
    margin-top: 40px;
    font-size: 20px;
    font-weight: 500;
    color: #8e8d8d;
  }
}
